import React, { Component } from "react";

import "./Hours.css";

const dayOfWeek = new Date().getDay();

var hours = [
  { day: "Sunday", hours: "7AM-2PM", dayOfWeek: 0 },
  { day: "Monday", hours: "CLOSED", dayOfWeek: 1 },
  { day: "Tuesday", hours: "CLOSED", dayOfWeek: 2 },
  { day: "Wednesday", hours: "7AM-2PM", dayOfWeek: 3 },
  { day: "Thursday", hours: "7AM-2PM", dayOfWeek: 4 },
  { day: "Friday", hours: "6AM-2PM", dayOfWeek: 5 },
  { day: "Saturday", hours: "6AM-2PM", dayOfWeek: 6 }
];

hours = hours.slice(dayOfWeek).concat(hours.slice(0, dayOfWeek));

class Hours extends Component {
  render() {
    return (
      <div className="mickies-hours">
        <div className="container">
          <h2>MICKIE'S HOURS</h2>
          <hr className="solid" />
          <p>
            {hours.map(day => (
              <div key={day.dayOfWeek}>
                {day.dayOfWeek === dayOfWeek ? (
                  <b className="current-day">
                    {day.day} : {day.hours}
                  </b>
                ) : (
                  <div>
                    {day.day} : {day.hours}
                  </div>
                )}
              </div>
            ))}
          </p>
          <p className="takeout-only">
            Open for <b>TAKEOUT and DINE IN</b>.
          </p>
          <p>
            Call us at{" "}
            <a href="tel:6082569476">
              <u>
                <b>(608) 256-9476</b>
              </u>
            </a>{" "}
            to place a takeout order
          </p>
        </div>
      </div>
    );
  }
}

export default Hours;
