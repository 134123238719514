import React, { Component } from "react";

import "./SocialMedia.css";

class SocialMedia extends Component {
  render() {
    return (
      <div className="container">
        Follow us on social media for regular updates.
        <br />
        <br />
        <a
          href="https://www.facebook.com/mickiesdairybar/"
          target="_blank"
          rel="noopener noreferrer"
          className="media-link"
        >
          <img
            src="/images/Facebook.png"
            height={20}
            alt="Facebook"
            style={{ marginBottom: "-1px", marginRight: "5px" }}
          />
          Follow us on Facebook
        </a>
        <br />
        <br />
        <a
          href="https://www.instagram.com/mickiesdairybar/"
          target="_blank"
          rel="noopener noreferrer"
          className="media-link"
        >
          <img
            src="/images/Instagram.png"
            height={20}
            alt="Instagram"
            style={{ marginBottom: "-1px", marginRight: "5px" }}
          />
          Follow us on Instagram @mickiesdairybar
        </a>
      </div>
    );
  }
}

export default SocialMedia;
