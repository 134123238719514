import React, { Component } from "react";
import SocialLinks from "./SocialLinks";

import "./Footer.css";

class Footer extends Component {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer">
        <div
          className="top-shadow"
          style={{ position: "absolute", zIndex: "100" }}
        >
          <div />
        </div>
        <div className="footer-logo">
          <b>
            <span className="mickies">Mickie's</span> DAIRY BAR
          </b>
        </div>
        <div>
          <SocialLinks />
        </div>
      </div>
    );
  }
}

export default Footer;
