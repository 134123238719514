import "./App.css";

import React from "react";
import { HashRouter, Route } from "react-router-dom";

import Menu from "./Menu";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

export default function App() {
  return (
    <HashRouter basename="/">
      <div className="App">
        <Header />
        <div>
          <Route exact path="/" component={Menu} />
          <Route path="/test" component={Header} />
        </div>
        <Footer />
      </div>
    </HashRouter>
  );
}
