import React from "react";

const SocialLinks = ({ extras }) => (
  <span>
    <a
      href="https://www.facebook.com/mickiesdairybar/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/Facebook.png"
        height={30}
        alt="Facebook"
        style={{ marginLeft: "20px", marginBottom: "-7px" }}
      />
    </a>
    <a
      href="https://www.instagram.com/mickiesdairybar/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/Instagram.png"
        height={30}
        alt="Instagram"
        style={{ marginLeft: "20px", marginBottom: "-7px" }}
      />
    </a>
  </span>
);

export default SocialLinks;
